
import { defineComponent, onMounted } from "vue";
import AddDiets from "@/components/customers/AddDiets.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "add-diets",
  components: {
    AddDiets,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("dataCollection.diets");
    });
  },
});
